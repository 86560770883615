<template>
  <div>
    <calendar-main
      :get-events="getEvents"
      :get-categories="getCategories"
      :add-permission="hasAddPermission"
      :edit-permission="hasEditPermission"
      :delete-permission="hasDeletePermission"
    />
  </div>
</template>

<script>
//libs
import { CalendarMain } from 'operations';

//constants
import _service from '@/api/operations.api.js';

// const calendarEventApi = new CalendarEventApi();
import applications from '@/utils/constants/applications';

export default {
  name: 'Calendar',
  components: {
    CalendarMain,
  },
  computed: {
    hasAddPermission() {
      return this.userHasPermissions('parents.calendar.add');
    },
    hasEditPermission() {
      return this.userHasPermissions('parents.calendar.edit');
    },
    hasDeletePermission() {
      return this.userHasPermissions('parents.calendar.delete');
    },
  },
  methods: {
    getEvents(categoryId) {
      return _service.fetchEvents(categoryId, applications.Parent);
    },
    getCategories() {
      return _service.fetchCategory();
    },
  },
};
</script>
